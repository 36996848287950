import { graphql, useStaticQuery } from 'gatsby';
import { SectionTitleEdge, AllDirectusSectionTitle } from './section-title.interface';

export default (): SectionTitleEdge[] => {
    const { allDirectusSectionTitle } = useStaticQuery<AllDirectusSectionTitle>(graphql`
    query {
      allDirectusSectionTitle {
        edges {
          node {
            directusId
            slug
            heading
            subheading
          }
        }
      }
    }
  `);

    return allDirectusSectionTitle.edges;
};
