import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusContactUsForm, ContactUsForm } from './contact-us-form.interface';

export default (): ContactUsForm[] => {
    const { allDirectusContactUsForm } = useStaticQuery<AllDirectusContactUsForm>(graphql`
    query {
      allDirectusContactUsForm {
        edges {
          node {
            directusId
            title
            body
            submit_button
            submitted_title
            submitted_body
            submitted_button
            default_error_message
          }
        }
      }
    }
  `);

    return allDirectusContactUsForm.edges;
};
