import React from 'react';

import Button from 'components/common/button/Button';
import { Props } from './FormSuccess.interface';

import s from '../ContactUsForm.scss';

const FormSuccess = ({ title, subTitle, body, handleFormReset, buttonText }: Props): JSX.Element => (
    <>
        <h2 className={s.title}>{title}</h2>
        <h3 className={s.subTitle}>{subTitle}</h3>
        <p className={s.body}>{body}</p>

        <Button type="button" role="secondary" onClick={handleFormReset} className={s.form__button}>
            {buttonText}
        </Button>
    </>
);

export default FormSuccess;
