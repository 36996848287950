import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusQuickAnswersCategory, QuickAnswersCategoryEdges } from './quick-answers.interface';

export default (): QuickAnswersCategoryEdges[] => {
    const { allDirectusQuickAnswersCategory } = useStaticQuery<AllDirectusQuickAnswersCategory>(graphql`
    query {
      allDirectusQuickAnswersCategory(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            name
            slug
            questions {
              directusId
              question
              answer
              sort
            }
          }
        }
      }
    }
  `);

    return allDirectusQuickAnswersCategory.edges;
};
