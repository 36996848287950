import React from 'react';

import Markdown from 'components/common/markdown/Markdown';
import { Props } from './SectionTitle.interface';

import s from './SectionTitle.scss';

export default ({ title, subtitle, align, underline, className }: Props): JSX.Element => {
    const alignClass = s[ `sectionTitle___${align}` ];
    const underlineClass = underline ? s.sectionTitle___underline : '';

    return (
        <div className={`${s.sectionTitle} ${alignClass} ${underlineClass} ${className || '' }`}>
            <h2 className={s.sectionTitle__heading}>
                <Markdown source={title} />
            </h2>
            { subtitle && (
                <h4 className={s.sectionTitle__subheading}>
                    <Markdown source={subtitle} />
                </h4>
            )}
        </div>
    );
};
