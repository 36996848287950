import React, { createRef, useState } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import * as yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-light-svg-icons';

import Button from 'components/common/button/Button';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { submitContactUs } from 'services';
import { getFieldBySlug } from 'utils/directus';
import FormSuccess from '../form-success/FormSuccess';
import { Props, FormValues } from './Form.interface';

import s from '../ContactUsForm.scss';

export default({ context }: Props): JSX.Element => {
    const { node } = getFieldBySlug('contact-us-form', sectionTitleHook());
    const refReCAPTCHA = createRef<any>();
    const [ isSubmitted, setIsSubmitted ] = useState<boolean>(false);
    const resetForm = () => setIsSubmitted(false);

    const encode = (data: any) => Object.keys(data)
        .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(data[ key ])}`)
        .join('&');

    if (isSubmitted) {
        return (
            <FormSuccess
                title={context.title}
                subTitle={context.submitted_body}
                body={context.body}
                buttonText={context.submitted_button}
                handleFormReset={() => resetForm()}
            />
        );
    }

    return (
        <div className={s.form__wrapper}>
            <SectionTitle title={node.heading} align="left" underline />
            <p className={s.body}>{context.body}</p>
            <Formik
                initialValues={{ name: '', email: '', question: '', recaptcha: '' }}
                validateOnChange={false}
                validateOnBlur={false}
                validationSchema={
                    yup.object().shape({
                        recaptcha: yup.string().required(),
                        name: yup.string().required(),
                        email: yup.string().required(),
                        question: yup.string().required(),
                    })
                }
                onSubmit={async (
                    values: FormValues,
                    { setSubmitting }: FormikHelpers<FormValues>,
                ) => {
                    fetch('/', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                        body: encode({
                            'form-name': 'contact',
                            ...values,
                        }),
                    }).then(() => {
                        setSubmitting(false);
                        setIsSubmitted(true);
                    }).catch(() => {
                        setSubmitting(false);
                    });
                }}
            >
                {(props) => {
                    const handleBlur = (e: any) => {
                        if (!props.values.recaptcha) {
                            refReCAPTCHA.current.execute();
                            props.setSubmitting(true);
                        }
                        props.handleBlur(e);
                    };

                    return (
                        <Form
                            className={s.form}
                            data-netlify
                            data-netlify-recaptcha
                        >
                            <label htmlFor="name" className={s.form__label}>
                                Name:
                                <input
                                    id="name"
                                    name="name"
                                    placeholder="John Doe"
                                    type="text"
                                    className={s.form__input}
                                    onChange={props.handleChange}
                                    onBlur={handleBlur}
                                    value={props.values.name}
                                    aria-invalid={props.errors.name ? 'true' : 'false'}
                                    aria-describedby="nameError"
                                />
                                <small className={s.form__error} id="nameError">
                                    {props.errors.name && 'Please enter your name.'}
                                </small>
                            </label>

                            <label htmlFor="email" className={s.form__label}>
                                Email:
                                <input
                                    id="email"
                                    name="email"
                                    placeholder="john@acme.com"
                                    type="email"
                                    className={s.form__input}
                                    onChange={props.handleChange}
                                    onBlur={handleBlur}
                                    value={props.values.email}
                                    aria-invalid={props.errors.email ? 'true' : 'false'}
                                    aria-describedby="emailError"
                                />
                                <small className={s.form__error} id="emailError">
                                    {props.errors.email && 'Please enter your email.'}
                                </small>
                            </label>

                            <label htmlFor="question" className={s.form__label}>
                                Question:
                                <textarea
                                    id="question"
                                    name="question"
                                    placeholder="Enter your question"
                                    rows={5}
                                    className={s.form__input}
                                    onChange={props.handleChange}
                                    onBlur={handleBlur}
                                    value={props.values.question}
                                    aria-invalid={props.errors.question ? 'true' : 'false'}
                                    aria-describedby="questionError"
                                />
                                <small className={s.form__error} id="questionError">
                                    {props.errors.question && 'Please enter your question.'}
                                </small>
                            </label>

                            <ReCAPTCHA
                                sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY as string}
                                ref={refReCAPTCHA}
                                onChange={(value) => {
                                    props.setFieldValue('recaptcha', value);
                                    props.setSubmitting(false);
                                }}
                                size="invisible"
                            />

                            <Button
                                type="submit"
                                role="primary"
                                size="large"
                                className={s.form__button}
                                disabled={props.isSubmitting}
                            >
                                {context.submit_button}
                                {props.isSubmitting && (
                                    <>
                                        &nbsp;<FontAwesomeIcon icon={faSpinner} spin />
                                    </>
                                )}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};
