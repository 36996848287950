import { graphql, useStaticQuery } from 'gatsby';

import { AllDirectusPageSection, PageSection } from './page-sections.interface';

export default (): PageSection[] => {
    const { allDirectusPageSection } = useStaticQuery<AllDirectusPageSection>(graphql`
        query {
            allDirectusPageSection(sort: { fields: sort, order: ASC }) {
                edges {
                    node {
                        directusId
                        sort
                        page {
                            directusId
                            slug
                            title
                        }
                        content
                    }
                }
            }
        }
    `);

    return allDirectusPageSection.edges;
};
