import React from 'react';
import slugify from 'react-slugify';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Markdown from 'components/common/markdown/Markdown';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import locationsHook from 'hooks/our-locations/our-locations.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome';

import s from './OurOffices.scss';

export default (): JSX.Element => {
    const locations = locationsHook();
    const { node } = getFieldBySlug('our-offices', sectionTitleHook());

    return (
        <>
            <SectionTitle title={node.heading} align="left" underline />

            <div className={s.ourOffices__wrapper}>
                {locations.map(({ node }) => (
                    <div className={s.ourOffices__office} key={`our-locations-${slugify(node.name)}`}>
                        <div>
                            <Img
                                fluid={node.image.localFile.childImageSharp.fluid}
                                alt={`Photograph of ${node.name} location.`}
                                className={s.ourOffices__image}
                                fadeIn
                            />
                        </div>

                        <div className={s.ourOffices__content}>
                            <Markdown source={node.address} className={s.ourOffices__address} container />

                            <div className={s.ourOffices__linkWrapper}>
                                <FontAwesomeIcon
                                    className={s.ourOffices__linkMarker}
                                    icon={getSolidFontAwesomeIcon('faMapMarkerAlt')}
                                />
                                <a href={node.google_map_link} className={s.ourOffices__link} target="_blank" rel="noopener noreferrer">
                                    View on Google maps
                                </a>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
