import React from 'react';

import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import callUsHook from 'hooks/call-us/call-us.hook';
import settingsHook from 'hooks/settings/settings.hook';
import { getFieldBySlug } from 'utils/directus';

import s from './CallUs.scss';

export default (): JSX.Element => {
    const calls = callUsHook();
    const settings = settingsHook();
    const { node } = getFieldBySlug('call-us', sectionTitleHook());

    return (
        <>
            <div className={s.callUs}>
                <SectionTitle title="Contact Us" align="left" underline />

                <div role="grid">
                    <div className={s.header} role="row">
                        <div role="gridcell">Address</div>
                        <div role="gridcell">Office Hours</div>
                        <div role="gridcell">Phone Number</div>
                    </div>

                    <div className={`${s.content} ${s.content___three}`} key={node.directusId} role="row">
                        <div role="gridcell">
                            {settings.brand_name},<br />
                            71-75, Shelton Street, <br /> Covent Garden, <br />London, WC2H 9JQ
                        </div>
                        <div role="gridcell">Monday to Sunday <br />Open 24 hours</div>
                        <div role="gridcell">{settings.telephone_number}</div>
                    </div>
                </div>
            </div>

            <div className={s.callUs}>
                <SectionTitle title="Other Helpful Numbers" align="left" underline />

                <div role="grid">
                    {calls.map(({ node }): JSX.Element => (
                        <div className={`${s.content} ${s.content___two}`} key={node.directusId} role="row">
                            <div role="gridcell">{node.title}</div>
                            <div role="gridcell">{node.telephone_uk}</div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};
