import React, { useState, useEffect } from 'react';
import Collapsible from 'react-collapsible';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import Markdown from 'components/common/markdown/Markdown';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import quickAnswersHook from 'hooks/quick-answers/quick-answers.hook';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus';

import s from './QuickAnswers.scss';

const OpenHeaderHTML = (question: string): JSX.Element => (
    <div className={s.quickAnswers__trigger}>
        <h3 className={s.quickAnswers__title}>{question}</h3>
        <span className={`${s.quickAnswers__arrow} ${s.quickAnswers__arrow___down}`} />
    </div>
);

const ClosedHeaderHTML = (question: string): JSX.Element => (
    <div className={s.quickAnswers__trigger}>
        <h3 className={s.quickAnswers__title}>{question}</h3>
        <span className={`${s.quickAnswers__arrow} ${s.quickAnswers__arrow___up}`} />
    </div>
);

export default (): JSX.Element => {
    const categories = quickAnswersHook();
    const [ category, updateSelectedCategory ] = useState<string>(categories[ 0 ].node.name);
    const [ isOpen, setIsOpen ] = useState(false);
    const { node } = getFieldBySlug('quick-answers', sectionTitleHook());

    const getSelectedCategory = (selectedCategory: string) => {
        const category = categories.filter(({ node }) => node.name === selectedCategory);
        return category[ 0 ].node.questions;
    };

    const setCategory = (category: string) => {
        setIsOpen(false);
        updateSelectedCategory(category);
    };

    return (
        <div className={s.quickAnswers__wrapper}>
            <SectionTitle title={node.heading} align="left" underline />

            <div className={s.quickAnswers__header}>
                <Collapsible
                    open={isOpen}
                    onOpening={() => setIsOpen(true)}
                    onClosing={() => setIsOpen(false)}
                    classParentString={s.quickAnswers__select}
                    trigger={(
                        <div className={s.quickAnswers__headerCategory}>
                            <span>{ category }</span>
                            <FontAwesomeIcon icon={faCaretDown} />
                        </div>
                    )}
                    triggerWhenOpen={(
                        <div className={s.quickAnswers__headerCategory}>
                            <span>{ category }</span>
                            <FontAwesomeIcon icon={faCaretDown} />
                        </div>
                    )}
                >
                    <ul>
                        {categories.map(({ node }, i) => (
                            <li key={`category-${node.slug}`}>
                                <div onClick={() => setCategory(node.name)} role="presentation">{node.name}</div>
                            </li>
                        ))}
                    </ul>
                </Collapsible>
            </div>

            <div className={s.quickAnswers__questions}>
                {getSelectedCategory(category).map((question: any) => (
                    <Collapsible
                        classParentString={s.quickAnswers__question}
                        trigger={ClosedHeaderHTML(question.question)}
                        triggerWhenOpen={OpenHeaderHTML(question.question)}
                        key={`question-category-${category}-question-${question.directusId}`}
                    >
                        <Markdown className={s.quickAnswers__answer} source={question.answer} container />
                    </Collapsible>
                ))}
            </div>
        </div>
    );
};
